import React, { useState, useEffect, useContext, useRef } from "react";
import { View, Text, ScrollView, StyleSheet, StatusBar, Image as RNImage, Platform, ActivityIndicator } from "react-native";

import { useSafeArea } from "react-native-safe-area-context";
import AuthContext from "../../context/AuthContext";

import * as Localization from "expo-localization";
import i18n from "i18n-js";
import translations from "../../locale/translations";
import Button from "../../components/common/Button";

import axios from "axios";
import jsPDF from "jspdf";

i18n.translations = translations;
i18n.locale = Localization.locale;
i18n.fallbacks = true;

const Certificate = ({ id, navigation, route }) => {
	const { userData, customization, dimensions } = useContext(AuthContext);
	const [loading, setLoading] = useState(true);
	const [imageSize, setImageSize] = useState(null);
	const [course, setCourse] = useState({});
	const [noCertificate, setNoCertificate] = useState(false);
	const canvasRef = useRef();

	const insets = useSafeArea();

	// VARIABLES BASICAS DE QUE CONTENIDO ESTAMOS USANDO
	const courseId = route.params.id;

	useEffect(() => {
		userData.tenants[0]
			.collection("courses")
			.doc(courseId)
			.get()
			.then((res) => {
				setCourse(res.data());
				if (res.data()?.certificate) {
					userData.tenants[0]
						.collection("certificates")
						.doc(res.data().certificate)
						.get()
						.then((res) => {
							paintCertificate(res.data());
						});
				} else {
					setNoCertificate(true);
				}
			});
	}, []);

	const paintCertificate = async (data) => {
		const ctx = canvasRef.current.getContext("2d");

		data.pages.map((p, i) => {
			RNImage.getSize(p?.background, async (width, height) => {
				canvasRef.current.height = data.pages.length * height;
				canvasRef.current.width = width;
				setImageSize({
					height: data.pages.length * height,
					width: width,
				});

				var bg = new Image();

				const back = await axios
					.post("https://europe-west1-uno-work.cloudfunctions.net/getBase64Image", {
						method: "POST",
						body: JSON.stringify({ url: p?.background }),
					})
					.then((response) => {
						// console.log("Success:", response);
						return response.data;
					})
					.catch((error) => {
						throw error;
					});
				bg.src = back;

				bg.onload = () => {
					ctx.drawImage(bg, 0, i === 0 ? 0 : i * height, width, height);
					p?.fields?.map((field, index) => {
						ctx.font = `bold ${field.fontSize}px Arial`;
						ctx.fillStyle = field.color;
						ctx.textBaseline = "hanging";
						ctx.fillText(
							field.id === "fullName"
								? userData.name + " " + userData.surname
								: field.id.includes("customAttributes")
								? userData.customAttributes[field.id.slice(17)]
								: userData[field.id],
							(field.position.x * width) / 100,
							(field.position.y * height) / 100
						);
					});
				};
			});
		});
		setLoading(false);
	};

	const downloadDiploma = () => {
		var imgData = canvasRef.current.toDataURL("image/jpeg", 0.2);
		var doc = new jsPDF("p", "mm", "a4");

		var width = doc.internal.pageSize.getWidth();
		var height = doc.internal.pageSize.getHeight();

		doc.addImage(imgData, "JPEG", 0, 0, width, height);
		doc.save("Certificado - " + course.name + ".pdf");
	};

	return (
		<ScrollView style={{ flex: 1, backgroundColor: "white" }}>
			{!noCertificate && (
				<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingHorizontal: 20, marginTop: 20 }}>
					{/* <Text>{courseId || ""}</Text> */}
					<Button onPress={downloadDiploma} label="Descargar en PDF" height={45} />
				</View>
			)}
			{noCertificate ? (
				<View style={{ flex: 1, paddingTop: 250, alignItems: "center", justifyContent: "center" }}>
					<View style={{ padding: 30, borderRadius: 15, backgroundColor: "#f3f3f3", alignItems: "center", justifyContent: "center" }}>
						<Text style={{ fontSize: 16, fontFamily: 'Regular' }}>No hay certificados disponibles para este curso</Text>
					</View>
				</View>
			) : (
				<View style={{ borderWidth: 2, borderRadius: 12, borderColor: "gray", margin: 10, overflow: "hidden" }}>
					{loading && (
						<View style={{position:"absolute", zIndex:10, top:250, left: "50%"}}>
							<ActivityIndicator />
						</View>
					)}
					<canvas ref={canvasRef} />
				</View>
			)}
		</ScrollView>
	);
};

export default Certificate;

const styles = StyleSheet.create({
	shadow: {
		shadowColor: "rgba(0, 0, 0, 0.3)",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.8,
		shadowRadius: 10,
		elevation: 10,
	},
});
