import React, { useState, useContext, useRef, useEffect } from "react";
import { View, Image, Text, Linking, StyleSheet, Dimensions, TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import AuthContext from "../context/AuthContext";
import { useNavigationState, useNavigation, Link as LinkNav } from "@react-navigation/native";
import { useSafeArea } from "react-native-safe-area-context";
import * as Animated from "react-native-animatable";

import { useHover, useFocus, useActive } from "react-native-web-hooks";
import Portal from "@burstware/react-native-portal/build/Portal";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";

const Link = ({ children, to = "#", onPress }) => {
	const { customization } = useContext(AuthContext);
	// Create a ref to bind the hooks to
	const ref = useRef(null);
	// Pass that ref to the hooks...
	const isHovered = useHover(ref);
	const isFocused = useFocus(ref);
	const isActive = useActive(ref);

	return (
		<View ref={ref}>
			<TouchableWithoutFeedback onPress={onPress}>
				<LinkNav
					accessibilityRole='link'
					draggable={false}
					to={to}
					tabIndex={0}
					style={[
						{
							color: customization.headerElementsColor,
							fontSize: 14,
						},
						// When these booleans become true, the following styles will be applied...
						isHovered && { color: customization.accentColor },
						isFocused && { color: customization.accentColor },
						isActive && { color: customization.accentColor },
					]}>
					{children}
				</LinkNav>
			</TouchableWithoutFeedback>
		</View>
	);
};

const NoNavLink = ({ children, to = "#", onPress }) => {
	const { customization } = useContext(AuthContext);
	// Create a ref to bind the hooks to
	const ref = useRef(null);
	// Pass that ref to the hooks...
	const isHovered = useHover(ref);
	const isFocused = useFocus(ref);
	const isActive = useActive(ref);

	return (
		<TouchableOpacity ref={ref} onPress={onPress}>
			<View
				accessibilityRole='link'
				draggable={false}
				tabIndex={0}
				style={[
					{
						color: customization.headerElementsColor,
						fontSize: 14,
					},
					// When these booleans become true, the following styles will be applied...
					isHovered && { color: customization.accentColor },
					isFocused && { color: customization.accentColor },
					isActive && { color: customization.accentColor },
				]}>
				{children}
			</View>
		</TouchableOpacity>
	);
};

const screenWidth = Dimensions.get("window").width;

const HeaderWeb = ({ navigation, toogleSmartCallback, smartContents }) => {
	const { authFunctions, userData, customization, dimensions } = useContext(AuthContext);

	const insets = useSafeArea();

	const [zonas, setZonas] = useState(userData.zonas);
	const [menu, showMenu] = useState(false);
	const [toogled, setToogled] = useState(false);

	return (
		<View style={[styles.shadow, { width: "100%", height: dimensions.width > 1000 ? 70 : 50, alignItems: "center", backgroundColor: customization.mainColor }]}>
			<View style={{ flex: 1, width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
				<View style={{ height: dimensions.width > 1000 ? 70 : 50, width: (dimensions.width > 1000 ? 55 : 45) * customization.logoRatio, alignItems: "center", justifyContent: "center" }}>
					<Image
						style={{ height: dimensions.width > 1000 ? 50 : 40, width: (dimensions.width > 1000 ? 50 : 40) * customization.logoRatio, resizeMode: "contain" }}
						source={{ uri: customization.logo }}
					/>
				</View>

				{/* {screenWidth <= 800 &&
                    <TouchableOpacity style={{ height: 50, width: 50, alignItems: "center", justifyContent: "center" }} onPress={() => showMenu(!menu)}>
                        <Ionicons color="white" size={35} name={menu ? "md-close" : "md-menu"} />
                    </TouchableOpacity>
                } */}
				{dimensions.width >= 1000 && <MenuLinks direction='row' zonas={zonas} authFunctions={authFunctions} />}
				{dimensions.width <= 1000 && smartContents && (
					<TouchableOpacity
						onPress={() => {
							toogleSmartCallback();
							setToogled(!toogled);
						}}
						style={{ borderRadius: 25, height: 45, flexDirection: "row", width: 50, alignItems: "center", justifyContent: "center" }}>
						{/* <Text style={{ fontFamily: 'DemiBold', marginRight:10 }}>{!toogled ? "Ver Contenidos" : "Ocultar Contenidos"}</Text> */}
						<Ionicons color={customization.headerElementsColor} name={toogled ? "md-close" : "menu"} size={32} />
					</TouchableOpacity>
				)}
				{/* {menu &&
                    <Portal>
                        <Animated.View style={{ position: "absolute", top: 70, right: 0, backgroundColor: "black", alignItems: "flex-end", justifyContent: "flex-start" }}>
                            <MenuLinks direction="column" zonas={zonas} clickCallback={() => showMenu(!menu)} />
                        </Animated.View>
                    </Portal>
                } */}
			</View>
		</View>
	);
};

const MenuLinks = ({ authFunctions, direction, zonas, clickCallback }) => (
	<View style={{ flex: 1, alignItems: direction === "row" ? "center" : "flex-end", justifyContent: "flex-end", flexDirection: direction }}>
		{zonas?.content && (
			<Link to='/StackContent/ContentHome' onPress={clickCallback}>
				<View style={{ flexDirection: "row", height: 70, paddingTop: 5, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }}>
					<Text style={{ fontFamily: "DemiBold" }}>Contenidos</Text>
					<Ionicons style={{ marginLeft: 15 }} name='md-home' size={30} />
				</View>
			</Link>
		)}

		{zonas?.smartlearning && (
			<Link to='/StackSmart/SmartHome' onPress={clickCallback}>
				<View onPress={() => navigation.navigate("StackSmart", {screen: "SmartHome"})} style={{ flexDirection: "row", height: 70, paddingTop: 5, alignItems: "center", justifyContent: "center", marginHorizontal: 20 }}>
					<Text style={{ fontFamily: "DemiBold" }}>Itinerario</Text>
					<Ionicons style={{ marginLeft: 15 }} name='md-book' size={30} />
				</View>
			</Link>
		)}
		{zonas?.itinerary && (
			<Link to='/StackItinerary/TrainingHome' onPress={clickCallback}>
				<View style={{ flexDirection: "row", height: 70, paddingTop: 5, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }}>
					<Text style={{ fontFamily: "DemiBold" }}>Desafíos</Text>
					<Ionicons style={{ marginLeft: 15 }} name='md-rocket' size={30} />
				</View>
			</Link>
		)}

		{zonas?.forum && (
			<Link to='/StackForum/ForumHome' onPress={clickCallback}>
				<View style={{ flexDirection: "row", height: 70, paddingTop: 5, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }}>
					<Text style={{ fontFamily: "DemiBold" }}>Foros</Text>
					<Ionicons style={{ marginLeft: 15 }} name='md-chatbubbles' size={30} />
				</View>
			</Link>
		)}

		{zonas?.ranking && (
			<Link to='/StackRanking/SocialAppraisal' onPress={clickCallback}>
				<View style={{ flexDirection: "row", height: 70, paddingTop: 5, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }}>
					<Text style={{ fontFamily: "DemiBold" }}>Rankings</Text>
					<Ionicons style={{ marginLeft: 15 }} name='md-trophy' size={30} />
				</View>
			</Link>
		)}

		<Link to='/StackProfile/ProfileHome' onPress={clickCallback}>
			<View style={{ flexDirection: "row", height: 70, paddingTop: 5, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }}>
				<Text style={{ fontFamily: "DemiBold" }}>Mi Perfil</Text>
				<Ionicons style={{ marginLeft: 15 }} name='person-circle' size={30} />
			</View>
		</Link>

		<NoNavLink onPress={authFunctions.signOut}>
			<View
				style={{
					flexDirection: "row",
					height: 70,
					paddingTop: 5,
					alignItems: "center",
					justifyContent: "center",
					marginHorizontal: 10,
					color: "white",
				}}>
				<Text style={{ fontFamily: "DemiBold", color: "white" }}>Salir</Text>
				<Ionicons style={{ marginLeft: 15 }} name='exit' color='white' size={30} />
			</View>
		</NoNavLink>
	</View>
);

const styles = StyleSheet.create({
	shadow: {
		shadowColor: "rgba(0, 0, 0, 0.3)",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.4,
		shadowRadius: 3,
		elevation: 10,
	},
});

export default HeaderWeb;
